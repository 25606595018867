/**
 * 套餐礼包API
 */

import request from '@/utils/request'

/**
 * 根据card_id查询套餐礼包
 * @param params
 * @returns {Promise<any>}
 */
 export function getByCard(params) {
  return request({
    url: 'seller/shopCombo/getByCard',
    method: 'get',
    params
  })
}

/**
 * 根据card_id查询套餐礼包List
 * @param params
 * @returns {Promise<any>}
 */
 export function getListByCard(params) {
  return request({
    url: 'seller/shopCombo/getListByCard',
    method: 'get',
    params
  })
}

/**
 * 获取绑定卡券列表
 * @param params
 * @returns {Promise<any>}
 */
 export function getCanPickedCardList(params) {
  return request({
    url: 'seller/card/comboCardList',
    method: 'get',
    params
  })
}

/**
 * 绑卡记录
 * @param params
 * @returns {Promise<any>}
 */
 export function getRecordList(params) {
  return request({
    url: 'seller/shopCombo/bindingCardPage',
    method: 'get',
    params
  })
}

/**
 * 挑选至卡券
 * @param params
 * @returns {Promise<any>}
 */
 export function combo2Card(cardId, data) {
  return request({
    url: `seller/shopCombo/bindingToCard/${cardId}`,
    method: 'post',
    data,
    headers: {'Content-Type': 'application/json'}
  })
}

/**
 * 查询店铺套餐礼包列表  GET
 * @param params
 * @returns {Promise<any>}
 */
export function getComboList(params) {
  return request({
    url: 'seller/shopCombo',
    method: 'get',
    params
  })
}

/**
 * 添加套餐礼包
 * @param params
 * @returns {Promise<any>}
 */
export function addCombo(data) {
  return request({
    url: 'seller/shopCombo',
    method: 'post',
    data,
    headers: {'Content-Type': 'application/json'}
  })
}

/**
* 查询店铺套餐礼包,编辑时使用
* @param params
* @returns {Promise<any>}
*/
export function getCombo(id) {
  return request({
    url: `seller/shopCombo/${id}`,
    method: 'get'
  })
}

/**
* 删除店铺套餐礼包
* @param params
* @returns {Promise<any>}
*/
export function delCombo(id) {
  return request({
    url: `seller/shopCombo/del/${id}`,
    method: 'delete'
  })
}

/**
* 编辑店铺套餐礼包
* @param params
* @returns {Promise<any>}
*/
export function editCombo(id, data) {
  return request({
    url: `seller/shopCombo/edit/${id}`,
    method: 'post',
    data,
    headers: {'Content-Type': 'application/json'}
  })
}

/**
* 禁用/启用 店铺套餐礼包
* @param params
* @returns {Promise<any>}
*/
export function changeStatus(id, status) {
  return request({
    url: `seller/shopCombo/updataStatus/${id}/${status}`,
    method: 'post'
  })
}
